import React from "react"

import { Link } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"

const IndexPage = () => (
  <Layout withBackground>
    <SEO title="Yams Studio" />
    <div className="flex flex-wrap">
      <div className="w-full md:w-1/2 m-5">
        <p className="text-xl md:text-3xl leading-none">
          Yams Studio was founded in 2015 with team members in NYC, LA and
          Berlin.
        </p>
        <p className="text-xl md:text-3xl leading-none my-3">
          We are a small but mighty team of creatives with an extended network
          of frequent collaborators and partners.
        </p>
        <p className="text-xl md:text-3xl leading-none my-3">
          We create digital and on-the-ground experiences that connect with
          diverse audiences worldwide.
        </p>
        <p className="text-xl md:text-3xl leading-none">
          We are culturally aware forward-thinkers.
        </p>
      </div>
      <div className="w-full md:w-1/3 m-5">
        <h2 className="text-base md:text-lg leading-tight uppercase">
          Clients
        </h2>
        <p className="text-base md:text-lg leading-tight mt-2">
          Nike, Epic Records, Illroots, Joe Freshgoods, Fat Tiger Workshop, Sole Classics, OATW, Trillectro, Heating Up Co., WOM, 3rdShiftNYC, SinceThe80s, CHNL80.
        </p>
        <h2 className="text-base md:text-lg leading-tight uppercase mt-8">
          Services
        </h2>
        <p className="text-base md:text-lg leading-tight mt-2">
          Design & Development: Visual / Web Design, Web Development, Apparel /
          Merchandising.
        </p>
        <p className="text-base md:text-lg leading-tight mt-2">
          Brand Building & Cultural Experiences: Research & Insights, Strategy /
          Ideation, Creative Direction, Event Production.
        </p>
        <p className="text-base md:text-lg leading-tight mt-2">
          Community:{" "}
          <Link
            to="/microsites"
            className="underline hover:text-teal-200 transition duration-150 ease-in-out"
          >
            Microsite Service.
          </Link>
        </p>
        <h2 className="text-base md:text-lg leading-tight uppercase mt-8">
          Shop
        </h2>
        <p className="text-base md:text-lg leading-tight mt-2">
          For merch and apparel, visit our{" "}
          <Link
            to="/shop"
            className="underline hover:text-teal-200 transition duration-150 ease-in-out"
          >
            online shop.
          </Link>
        </p>
      </div>
    </div>
  </Layout>
)

export default IndexPage
